import React from 'react';

interface Template {
  title: string;
  description: string;
  image: string;
  slug: string;
  category: string;
}

interface TemplateCardProps {
  template: Template;
  onViewDetail: () => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template, onViewDetail }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img src={template.image} alt={template.title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{template.title}</h3>
        <p className="text-gray-600 mb-4">{template.description}</p>
        <button
          onClick={onViewDetail}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
        >
          Ver detalle
        </button>
      </div>
    </div>
  );
};

export default TemplateCard;