import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './pages/Home';
import Examples from './pages/Examples';
import About from './pages/About';
import Contact from './pages/Contact';
import BusinessProDemoPage from './pages/demo/business-pro';
import StartupLaunchDemoPage from './pages/demo/startup-launch';
import CreativeShowcaseDemoPage from './pages/demo/creative-showcase';
import './styles/main.css';

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ejemplos" element={<Examples />} />
          <Route path="/sobre-nosotros" element={<About />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/demo/business-pro" element={<BusinessProDemoPage />} />
          <Route path="/demo/startup-launch" element={<StartupLaunchDemoPage />} />
          <Route path="/demo/creative-showcase" element={<CreativeShowcaseDemoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
