import React, { useState } from 'react';
import Navbar from '../components/layout/Navbar';
import { Link } from 'react-router-dom';
import '../styles/main.css';

const Home: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>('all');

  const handleFilterClick = (category: string) => {
    setActiveCategory(category);
  };

  const templates = [
    {
      title: 'Business Pro',
      category: 'business',
      image: require('../assets/images/business-pro-thumb.jpg'),
      link: '/plantillas/business-pro',
      price: '$49',
      technologies: 'React, Node.js, CSS',
    },
    {
      title: 'Creative Showcase',
      category: 'portfolio',
      image: require('../assets/images/creative-showcase-thumb.jpg'),
      link: '/plantillas/creative-showcase',
      price: '$39',
      technologies: 'HTML, CSS, JavaScript',
    },
    {
      title: 'Shop Smart',
      category: 'ecommerce',
      image: require('../assets/images/shop-smart-thumb.jpg'),
      link: '/plantillas/shop-smart',
      price: '$59',
      technologies: 'Shopify, Liquid',
    },
    {
      title: 'Blog Master',
      category: 'blog',
      image: require('../assets/images/blog-master-thumb.jpg'),
      link: '/plantillas/blog-master',
      price: '$29',
      technologies: 'WordPress, PHP',
    },
    {
      title: 'Startup Launch',
      category: 'business',
      image: require('../assets/images/startup-launch-thumb.jpg'),
      link: '/plantillas/startup-launch',
      price: '$49',
      technologies: 'React, Redux',
    },
    {
      title: 'Artist Gallery',
      category: 'portfolio',
      image: require('../assets/images/artist-gallery-thumb.jpg'),
      link: '/plantillas/artist-gallery',
      price: '$39',
      technologies: 'Vue.js, CSS',
    },
  ];

  return (
    <div>
      <Navbar />

      <main className="pt-20">
        <section className="page-header bg-neutral-light py-12 text-center">
          <div className="container mx-auto">
            <h1 className="page-title text-5xl font-bold mb-4">Nuestras Plantillas</h1>
            <p className="text-lg">Descubre la plantilla perfecta para tu negocio</p>
          </div>
        </section>

        <section className="filter-bar">
          <div className="container mx-auto">
            <div className="filter-options flex justify-center gap-4">
              {['all', 'business', 'portfolio', 'ecommerce', 'blog'].map((category) => (
                <button
                  key={category}
                  className={`filter-button ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => handleFilterClick(category)}
                >
                  {category === 'all' ? 'Todas' : category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </section>

        <section className="templates-grid container mx-auto py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {templates.map((template) => (
            (activeCategory === 'all' || activeCategory === template.category) && (
              <div key={template.title} className="template-card bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105" data-category={template.category}>
                <img src={template.image} alt={`Vista previa de la plantilla ${template.title}`} className="template-image w-full h-48 object-cover" />
                <div className="template-info p-4">
                  <h2 className="template-title text-xl font-semibold mb-2">{template.title}</h2>
                  <p className="template-category text-secondary mb-2">{template.category.charAt(0).toUpperCase() + template.category.slice(1)}</p>
                  <p className="template-price text-lg font-bold text-primary mb-2">{template.price}</p>
                  <p className="template-technologies text-sm text-gray-600 mb-4">Tecnologías: {template.technologies}</p>
                  <Link to={template.link} className="template-link inline-block bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition">Ver detalles</Link>
                </div>
              </div>
            )
          ))}
        </section>
      </main>
    </div>
  );
};

export default Home;
