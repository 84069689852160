import React, { useState } from 'react';
import { Menu, X, ChevronRight, Briefcase, TrendingUp, Users, Star, ArrowRight } from 'lucide-react';

const BusinessProDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="font-sans text-gray-800 bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-40">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-10 h-10 mr-2" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="8" fill="#3B82F6" />
              <path d="M12 20H28M20 12V28" stroke="white" strokeWidth="3" strokeLinecap="round" />
            </svg>
            <span className="text-2xl font-bold text-gray-800">Business Pro</span>
          </div>
          <nav className="hidden md:flex space-x-8">
            {['Inicio', 'Servicios', 'Testimonios', 'Contacto'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="text-gray-600 hover:text-blue-600 transition duration-300">
                {item}
              </a>
            ))}
          </nav>
          <button onClick={toggleMenu} className="md:hidden text-gray-600 hover:text-blue-600">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-white py-2 px-4">
            {['Inicio', 'Servicios', 'Testimonios', 'Contacto'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="block py-2 text-gray-600 hover:text-blue-600">
                {item}
              </a>
            ))}
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section id="inicio" className="pt-32 pb-20 bg-gradient-to-br from-blue-500 to-blue-700 text-white">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">Soluciones Profesionales para tu Empresa</h1>
          <p className="text-xl mb-10 max-w-2xl mx-auto">Impulsamos el crecimiento de tu negocio con estrategias innovadoras y resultados comprobados.</p>
          <a href="#contacto" className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300 inline-flex items-center">
            Contáctanos
            <ChevronRight className="ml-2" size={20} />
          </a>
        </div>
      </section>

      {/* Services Section */}
      <section id="servicios" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Nuestros Servicios</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: <Briefcase size={40} />, title: "Consultoría Estratégica", description: "Desarrollamos planes personalizados para impulsar tu negocio al siguiente nivel." },
              { icon: <TrendingUp size={40} />, title: "Gestión de Proyectos", description: "Optimizamos tus procesos para aumentar la eficiencia y productividad." },
              { icon: <Users size={40} />, title: "Desarrollo de Negocios", description: "Identificamos oportunidades de crecimiento y expansión para tu empresa." }
            ].map((service, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-lg shadow-md text-center hover:shadow-xl transition duration-300">
                <div className="text-blue-500 mb-4 inline-block">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonios" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Lo que dicen nuestros clientes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { quote: "Business Pro transformó por completo nuestra estrategia de negocio. Su enfoque innovador y su dedicación son incomparables.", author: "María Rodríguez", role: "CEO de TechSolutions" },
              { quote: "Gracias a la consultoría de Business Pro, hemos duplicado nuestros ingresos en solo un año. Su equipo es verdaderamente excepcional.", author: "Carlos Méndez", role: "Fundador de GreenEnergy" }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md relative">
                <Star className="absolute top-4 right-4 text-yellow-400" size={24} />
                <p className="text-gray-600 mb-4 italic">"{testimonial.quote}"</p>
                <div className="font-semibold">{testimonial.author}</div>
                <div className="text-sm text-gray-500">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section id="contacto" className="py-20 bg-blue-600 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">¿Listo para llevar tu negocio al siguiente nivel?</h2>
          <p className="text-xl mb-10 max-w-2xl mx-auto">Descubre cómo podemos ayudarte a alcanzar tus objetivos empresariales. Agenda una consulta gratuita hoy mismo.</p>
          <a href="#" className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300 inline-flex items-center">
            Solicita una consulta gratuita
            <ArrowRight className="ml-2" size={20} />
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2023 Business Pro. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default BusinessProDemo;