import React, { useState } from 'react';
import { Menu, X, Rocket, Target, Users, Star, ArrowRight } from 'lucide-react';

const StartupLaunchDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="font-sans text-gray-800 bg-white min-h-screen">
      {/* Header */}
      <header className="bg-orange-500 text-white fixed top-0 left-0 right-0 z-40">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <Rocket size={32} className="mr-2" />
            <span className="text-2xl font-bold">Startup Launch</span>
          </div>
          <nav className="hidden md:flex space-x-8">
            {['Inicio', 'Producto', 'Misión', 'Testimonios'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="text-white hover:text-green-300 transition duration-300">
                {item}
              </a>
            ))}
          </nav>
          <button onClick={toggleMenu} className="md:hidden text-white">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-orange-600 py-2 px-4">
            {['Inicio', 'Producto', 'Misión', 'Testimonios'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="block py-2 text-white hover:text-green-300">
                {item}
              </a>
            ))}
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section id="inicio" className="pt-32 pb-20 bg-gradient-to-br from-orange-500 to-green-500 text-white">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in-down">Revoluciona tu Industria con Startup Launch</h1>
          <p className="text-xl mb-10 max-w-2xl mx-auto animate-fade-in-up">Nuestra plataforma de IA te ayuda a tomar decisiones más inteligentes y rápidas.</p>
          <a href="#producto" className="bg-white text-orange-500 px-8 py-3 rounded-full font-semibold hover:bg-orange-100 transition duration-300 inline-flex items-center animate-bounce">
            Descubre Cómo
            <ArrowRight className="ml-2" size={20} />
          </a>
        </div>
      </section>

      {/* Product Section */}
      <section id="producto" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Nuestro Producto Revolucionario</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img src="/api/placeholder/500/300" alt="Startup Launch Product" className="rounded-lg shadow-xl" />
            </div>
            <div className="md:w-1/2 md:pl-8">
              <h3 className="text-2xl font-semibold mb-4">IA para Toma de Decisiones</h3>
              <p className="text-gray-600 mb-6">Nuestra plataforma utiliza algoritmos avanzados de inteligencia artificial para analizar datos complejos y proporcionar insights accionables en tiempo real.</p>
              <ul className="space-y-2">
                {['Análisis predictivo', 'Optimización de recursos', 'Detección de patrones', 'Reportes personalizados'].map((feature, index) => (
                  <li key={index} className="flex items-center text-gray-700">
                    <Star className="text-green-500 mr-2" size={20} />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section id="misión" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Nuestra Misión</h2>
          <p className="text-xl mb-12 max-w-3xl mx-auto">Empoderar a las empresas con herramientas de IA para tomar decisiones más inteligentes y crear un futuro más eficiente y sostenible.</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: <Rocket size={40} />, title: "Innovación Constante", description: "Desarrollamos soluciones de vanguardia para mantenernos a la cabeza de la industria." },
              { icon: <Target size={40} />, title: "Impacto Medible", description: "Nos enfocamos en proporcionar resultados tangibles y cuantificables para nuestros clientes." },
              { icon: <Users size={40} />, title: "Colaboración", description: "Trabajamos en estrecha colaboración con nuestros clientes para garantizar su éxito." }
            ].map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-xl transition duration-300">
                <div className="text-orange-500 mb-4 inline-block">{item.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonios" className="py-20 bg-green-500 text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Lo que dicen nuestros clientes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { quote: "Startup Launch ha transformado completamente nuestra forma de tomar decisiones. Los insights que obtenemos son invaluables.", author: "Ana Martínez", role: "CEO de TechInnovate" },
              { quote: "Desde que implementamos Startup Launch, hemos visto un aumento del 40% en nuestra eficiencia operativa. Es simplemente asombroso.", author: "Carlos Ruiz", role: "COO de FutureNow" }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white text-gray-800 p-6 rounded-lg shadow-md relative">
                <Star className="absolute top-4 right-4 text-yellow-400" size={24} />
                <p className="text-gray-600 mb-4 italic">"{testimonial.quote}"</p>
                <div className="font-semibold">{testimonial.author}</div>
                <div className="text-sm text-gray-500">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-orange-500 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">¿Listo para revolucionar tu negocio?</h2>
          <p className="text-xl mb-10 max-w-2xl mx-auto">Únete a las empresas visionarias que ya están aprovechando el poder de la IA para impulsar su crecimiento.</p>
          <a href="#" className="bg-white text-orange-500 px-8 py-3 rounded-full font-semibold hover:bg-orange-100 transition duration-300 inline-flex items-center">
            Solicita una demo gratuita
            <ArrowRight className="ml-2" size={20} />
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2023 Startup Launch. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default StartupLaunchDemo;