import React from 'react';
import Navbar from '../components/layout/Navbar'; // Ruta corregida
import '../styles/main.css'; // Asegúrate de que esta ruta sea correcta

const AboutUs: React.FC = () => {
  return (
    <>
      <Navbar />

      <main className="pt-20">
        <section className="page-header bg-neutral-light py-12 text-center">
          <div className="container mx-auto">
            <h1 className="page-title text-5xl mb-4">Sobre Nosotros</h1>
            <p>Conoce al equipo detrás de Danick y nuestra misión</p>
          </div>
        </section>

        <section className="about-section">
          <div className="container mx-auto">
            <div className="about-content flex flex-col md:flex-row">
              <div className="about-image md:w-1/2">
                <img
                  src="/images/about-us-office.jpg"
                  alt="Oficina de Danick con equipo trabajando"
                  width="500"
                  height="333"
                  className="w-full h-auto"
                />
              </div>
              <div className="about-text md:w-1/2 p-4">
                <h2 className="text-2xl mb-4">Nuestra Historia</h2>
                <p>
                  Danick nació de la pasión por el diseño web y la necesidad de simplificar la creación de landing pages efectivas. 
                  Fundada en 2020 por un grupo de diseñadores y desarrolladores web, nuestra misión es democratizar el acceso a 
                  landing pages de alta calidad para emprendedores y pequeñas empresas.
                </p>
                <p>
                  Creemos que cada negocio merece tener una presencia en línea impactante, sin importar su tamaño o presupuesto. 
                  Con Danick, hacemos realidad esa visión al ofrecer plantillas personalizables y fáciles de usar que se adaptan 
                  a una amplia gama de industrias y necesidades.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="team-section">
          <div className="container mx-auto">
            <h2 className="text-2xl mb-4">Nuestro Equipo</h2>
            <div className="team-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  name: 'Ana López',
                  role: 'Fundadora y CEO',
                  image: '/images/team-member-1.jpg',
                  alt: 'Foto de Ana López, Fundadora y CEO',
                },
                {
                  name: 'Carlos Rodríguez',
                  role: 'Director de Diseño',
                  image: '/images/team-member-2.jpg',
                  alt: 'Foto de Carlos Rodríguez, Director de Diseño',
                },
                {
                  name: 'María Sánchez',
                  role: 'Jefa de Desarrollo',
                  image: '/images/team-member-3.jpg',
                  alt: 'Foto de María Sánchez, Jefa de Desarrollo',
                },
                {
                  name: 'David Torres',
                  role: 'Especialista en Marketing',
                  image: '/images/team-member-4.jpg',
                  alt: 'Foto de David Torres, Especialista en Marketing',
                },
              ].map((member) => (
                <div key={member.name} className="team-member text-center">
                  <img
                    src={member.image}
                    alt={member.alt}
                    width="250"
                    height="250"
                    className="rounded-full mb-2"
                  />
                  <div className="team-member-info">
                    <h3 className="team-member-name font-semibold">{member.name}</h3>
                    <p>{member.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutUs;
