import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow fixed w-full z-50">
      <div className="container mx-auto flex justify-between items-center h-16 p-4">
        <Link to="/" className="text-2xl font-bold text-primary">
          Dan<span className="text-secondary">ick</span>
        </Link>
        
        {/* Botón hamburguesa */}
        <button 
          className="md:hidden p-2 focus:outline-none"
          onClick={toggleMenu}
        >
          {isMenuOpen ? '✖' : '☰'}
        </button>

        {/* Menú de navegación */}
        <div className={`md:flex space-x-8 ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
          <Link to="/" className="hover:text-primary">Inicio</Link>
          <Link to="/ejemplos" className="hover:text-primary">Ejemplos</Link>
          <Link to="/sobre-nosotros" className="hover:text-primary">Sobre Nosotros</Link>
          <Link to="/contacto" className="hover:text-primary">Contacto</Link>
        </div>
      </div>
    </nav>
  );
}
