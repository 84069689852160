import React from 'react';
import Navbar from '../components/layout/Navbar'; // Ruta corregida
import '../styles/main.css'; // Asegúrate de que esta ruta sea correcta

const Contact: React.FC = () => {
  return (
    <>
      <Navbar />

      <main className="pt-20">
        <section className="page-header bg-neutral-light py-12 text-center">
          <div className="container mx-auto">
            <h1 className="page-title text-5xl mb-4">Contacto</h1>
            <p>¿Tienes alguna pregunta? ¡Estamos aquí para ayudarte!</p>
          </div>
        </section>

        <section className="contact-section">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
            <form className="contact-form">
              <div className="form-group mb-4">
                <input type="text" placeholder="Nombre" className="w-full p-2 border border-gray-300 rounded" required />
              </div>
              <div className="form-group mb-4">
                <input type="email" placeholder="Correo Electrónico" className="w-full p-2 border border-gray-300 rounded" required />
              </div>
              <div className="form-group mb-4">
                <input type="text" placeholder="Asunto" className="w-full p-2 border border-gray-300 rounded" required />
              </div>
              <div className="form-group mb-4">
                <textarea placeholder="Mensaje" className="w-full p-2 border border-gray-300 rounded" required></textarea>
              </div>
              <button type="submit" className="bg-primary text-white py-2 px-4 rounded">Enviar Mensaje</button>
            </form>

            <div className="contact-info">
              <div className="info-item mb-4">
                <h3 className="font-semibold">Dirección</h3>
                <p>Calle Innovación 123, Ciudad Creativa, 28001</p>
              </div>
              <div className="info-item mb-4">
                <h3 className="font-semibold">Teléfono</h3>
                <p>+34 910 123 456</p>
              </div>
              <div className="info-item mb-4">
                <h3 className="font-semibold">Email</h3>
                <p>info@danick.com</p>
              </div>
              <div className="info-item mb-4">
                <h3 className="font-semibold">Horario de Atención</h3>
                <p>Lunes a Viernes: 9:00 AM - 6:00 PM</p>
              </div>
              <div className="social-links mt-4">
                <a href="https://facebook.com/danick" target="_blank" rel="noopener noreferrer" className="mr-4">Facebook</a>
                <a href="https://twitter.com/danick" target="_blank" rel="noopener noreferrer" className="mr-4">Twitter</a>
                <a href="https://instagram.com/danick" target="_blank" rel="noopener noreferrer" className="mr-4">Instagram</a>
                <a href="https://linkedin.com/company/danick" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
