import React, { useState } from 'react';
import Navbar from '../components/layout/Navbar';
import TemplateCard from '../components/templates/TemplateCard';
import '../styles/main.css';

// Import demo components
import BusinessProDemo from '../components/templates/BusinessProDemo';
import StartupLaunchDemo from '../components/templates/StartupLaunchDemo';
import CreativeShowcaseDemo from '../components/templates/CreativeShowcaseDemo';

// Import images
import businessProImg from '../assets/images/business-pro-thumb.jpg';
import startupLaunchImg from '../assets/images/startup-launch-thumb.jpg';
import creativeShowcaseImg from '../assets/images/creative-showcase-thumb.jpg';
import artistGalleryImg from '../assets/images/artist-gallery-thumb.jpg';
import shopSmartImg from '../assets/images/shop-smart-thumb.jpg';
import ecommerceProImg from '../assets/images/ecommerce-pro-thumb.jpg';
import blogMasterImg from '../assets/images/blog-master-thumb.jpg';
import personalBlogImg from '../assets/images/personal-blog-thumb.jpg';

export default function Examples() {
  const [activeCategory, setActiveCategory] = useState<string>('all');
  const [activeDemoSlug, setActiveDemoSlug] = useState<string | null>(null);

  const handleViewDetail = (slug: string) => {
    setActiveDemoSlug(slug);
  };

  const handleCloseDemo = () => {
    setActiveDemoSlug(null);
  };

  const handleFilterClick = (category: string) => {
    setActiveCategory(category);
  };

  const templates = [
    {
      title: 'Business Pro',
      description: 'Ideal para empresas que buscan una presencia profesional y confiable.',
      image: businessProImg,
      slug: 'business-pro',
      category: 'business',
    },
    {
      title: 'Startup Launch',
      description: 'Perfecto para startups que desean presentar su proyecto de manera impactante.',
      image: startupLaunchImg,
      slug: 'startup-launch',
      category: 'business',
    },
    {
      title: 'Creative Showcase',
      description: 'Diseñado para artistas y creativos que quieren mostrar su trabajo.',
      image: creativeShowcaseImg,
      slug: 'creative-showcase',
      category: 'portfolio',
    },
    {
      title: 'Artist Gallery',
      description: 'Un espacio visual para artistas, ideal para portfolios personalizados.',
      image: artistGalleryImg,
      slug: 'artist-gallery',
      category: 'portfolio',
    },
    {
      title: 'Shop Smart',
      description: 'Todo lo que necesitas para crear tu tienda en línea de forma efectiva.',
      image: shopSmartImg,
      slug: 'shop-smart',
      category: 'ecommerce',
    },
    {
      title: 'E-commerce Pro',
      description: 'Plantilla optimizada para maximizar tus ventas online.',
      image: ecommerceProImg,
      slug: 'ecommerce-pro',
      category: 'ecommerce',
    },
    {
      title: 'Blog Master',
      description: 'Ideal para bloggers que desean una plataforma limpia y funcional.',
      image: blogMasterImg,
      slug: 'blog-master',
      category: 'blog',
    },
    {
      title: 'Personal Blog',
      description: 'Diseñada para compartir tus pensamientos y experiencias de manera atractiva.',
      image: personalBlogImg,
      slug: 'personal-blog',
      category: 'blog',
    },
  ];

  const renderDemo = () => {
    switch (activeDemoSlug) {
      case 'business-pro':
        return <BusinessProDemo />;
      case 'startup-launch':
        return <StartupLaunchDemo />;
      case 'creative-showcase':
        return <CreativeShowcaseDemo />;
      // Agrega más casos para otros demos a medida que los crees
      default:
        return null;
    }
  };

  if (activeDemoSlug) {
    return (
      <div className="relative">
        {renderDemo()}
        <button
          onClick={handleCloseDemo}
          className="fixed top-4 right-4 bg-white text-gray-800 px-4 py-2 rounded shadow-md hover:bg-gray-100 transition duration-300"
        >
          Cerrar Demo
        </button>
      </div>
    );
  }

  return (
    <div>
      <Navbar />

      <main className="pt-20">
        <section className="page-header bg-neutral-light py-12 text-center">
          <div className="container mx-auto">
            <h1 className="page-title text-5xl mb-4">Ejemplos de Plantillas</h1>
            <p>Explora nuestras plantillas y encuentra la solución perfecta para tu negocio.</p>
          </div>
        </section>

        <section className="filter-bar">
          <div className="container mx-auto">
            <div className="filter-options flex justify-center gap-4">
              {['all', 'business', 'portfolio', 'ecommerce', 'blog'].map((category) => (
                <button
                  key={category}
                  className={`filter-button ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => handleFilterClick(category)}
                >
                  {category === 'all' ? 'Todas' : category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </section>

        <section className="templates-grid container mx-auto py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {templates.map((template) => (
            (activeCategory === 'all' || activeCategory === template.category) && (
              <TemplateCard 
                key={template.slug} 
                template={template} 
                onViewDetail={() => handleViewDetail(template.slug)}
              />
            )
          ))}
        </section>
      </main>
    </div>
  );
}
