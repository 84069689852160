import React, { useState } from 'react';
import { Menu, X, Camera, Paintbrush, Film, Music } from 'lucide-react';

const CreativeShowcaseDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const works = [
    { title: 'Naturaleza en Movimiento', category: 'Fotografía', image: '/api/placeholder/400/300' },
    { title: 'Abstracto No. 5', category: 'Pintura', image: '/api/placeholder/400/300' },
    { title: 'Ciudad Nocturna', category: 'Fotografía', image: '/api/placeholder/400/300' },
    { title: 'Melodía del Viento', category: 'Música', image: '/api/placeholder/400/300' },
    { title: 'Emociones', category: 'Cortometraje', image: '/api/placeholder/400/300' },
    { title: 'Explosión de Color', category: 'Pintura', image: '/api/placeholder/400/300' },
  ];

  return (
    <div className="font-sans text-gray-800 bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="bg-purple-600 text-white fixed top-0 left-0 right-0 z-40">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <Paintbrush size={32} className="mr-2" />
            <span className="text-2xl font-bold">Creative Showcase</span>
          </div>
          <nav className="hidden md:flex space-x-8">
            {['Inicio', 'Galería', 'Sobre Mí', 'Contacto'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="text-white hover:text-yellow-300 transition duration-300">
                {item}
              </a>
            ))}
          </nav>
          <button onClick={toggleMenu} className="md:hidden text-white">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-purple-700 py-2 px-4">
            {['Inicio', 'Galería', 'Sobre Mí', 'Contacto'].map((item) => (
              <a key={item} href={`#${item.toLowerCase()}`} className="block py-2 text-white hover:text-yellow-300">
                {item}
              </a>
            ))}
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section id="inicio" className="pt-32 pb-20 bg-gradient-to-br from-purple-600 to-pink-500 text-white">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in-down">Bienvenido a mi Mundo Creativo</h1>
          <p className="text-xl mb-10 max-w-2xl mx-auto animate-fade-in-up">Explora mi colección de obras que abarcan fotografía, pintura, cine y música.</p>
          <a href="#galería" className="bg-white text-purple-600 px-8 py-3 rounded-full font-semibold hover:bg-purple-100 transition duration-300 inline-flex items-center animate-bounce">
            Ver Galería
          </a>
        </div>
      </section>

      {/* Gallery Section */}
      <section id="galería" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Mi Galería</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {works.map((work, index) => (
              <div key={index} className="bg-gray-100 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition duration-300">
                <img src={work.image} alt={work.title} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{work.title}</h3>
                  <p className="text-gray-600">{work.category}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Me Section */}
      <section id="sobre mí" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Sobre Mí</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img src="/api/placeholder/400/400" alt="Artist" className="rounded-full shadow-xl mx-auto" />
            </div>
            <div className="md:w-1/2 md:pl-8">
              <p className="text-gray-600 mb-6">Soy un artista multidisciplinario apasionado por la creación. Mi trabajo abarca diversas formas de arte, desde la fotografía hasta la música, cada pieza refleja una parte de mi alma. Siempre busco explorar nuevas ideas y técnicas, compartiendo mi visión única con el mundo.</p>
              <p className="text-gray-600">Mi objetivo es conectar emocionalmente con las personas a través de mis obras, inspirando creatividad y reflexión. ¡Gracias por visitar mi portfolio y ser parte de este viaje!</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contacto" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Contacto</h2>
          <form className="max-w-lg mx-auto">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="name">Nombre</label>
              <input type="text" id="name" className="w-full p-3 border rounded" placeholder="Tu nombre" required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="email">Correo Electrónico</label>
              <input type="email" id="email" className="w-full p-3 border rounded" placeholder="Tu correo" required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="message">Mensaje</label>
              <textarea
  id="message"
  className="w-full p-3 border rounded"
  rows={4} // Cambiado a número
  placeholder="Escribe tu mensaje aquí"
  required
></textarea>
            </div>
            <button type="submit" className="bg-purple-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-purple-700 transition duration-300">
              Enviar Mensaje
            </button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm">&copy; {new Date().getFullYear()} Creative Showcase. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default CreativeShowcaseDemo;
